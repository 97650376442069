import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import IOHeadline from 'components/io-headline';
import { Intro, IntroText } from 'components/intro/intro.css';
import { Link } from 'components/link/link.css';
import { AnimatedContainer } from 'components/header';

import { graphql } from 'gatsby';

const Contact = ({ data }) => (
  <Layout>
    <IOHeadline as="h1" size="huge">
      <Intro>
        <IntroText>{data.contactJson.title}</IntroText>
      </Intro>
    </IOHeadline>
    <AnimatedContainer>
      <Link href={'https://calendly.com/patrickludewig/30min'}>
        Schedule a Call
      </Link>
      <p color="black">
        Since i’m planning my time in advance, i cannot take calls unscheduled.
        I want to be mentally prepared for everyone who is communicating with
        me, so it needs to be scheduled call to reach me via phone.
        <strong>I’m faster and more responsive via e-mail.</strong>
      </p>
      <Link
        href={
          'mailto:yes@patrick.works?subject=%F0%9F%92%ADpatrick%2C%20i%20have%20an%20idea&body=Hej%20patrick%2C%0A%0A%F0%9F%92%ADmy%20idea%2Fproject%3A%0A%0A%0A%F0%9F%92%B0budget%3A%0A(e.g.%202000-5000%E2%82%AC)%0A%0A%F0%9F%97%93%20deadline%3A%0A%0A%0A%F0%9F%93%9Ecall%20me%3A%0A%0A%0Abest%20wishes%2C%0A%0A'
        }
      >
        yes@patrick.works
      </Link>
      <Link href={'https://instagram.com/maybach_'}>Instagram</Link>
      <Link href={'https://twitter.com/maybach'}>Twitter</Link>
      <Link href={'https://github.com/maybach91'}>Github</Link>
    </AnimatedContainer>
  </Layout>
);

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contact;

export const query = graphql`
  query ContactQuery {
    contactJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
  }
`;
