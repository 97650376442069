import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Link = styled.a`
  font-size: 1.4rem;
  display: block;
  padding: 1rem 0;
  ${MEDIA.MIN_TABLET`
    font-size: 2rem;
  `};
  ${MEDIA.MIN_DESKTOP`
    font-size: 4rem;
  `};
`;
